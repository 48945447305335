import {mobileCode, registerConfig, wechatMobileCode} from '@/api/auth/login'
import {adList, captcha} from '@/api/website'

import Vue from 'vue'
const vm = new Vue()

export default {
  data: function () {
    var isMobile = function (rule, value, callback) {
      if (!value) {
        return callback(new Error(this.$lang('tmdDOTco.00337', this.$route)))
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error(this.$lang('tmdDOTco.00338', this.$route)))
        }
      }
    }

    return {
      qrcodeData: {
        time: 0,
        timer: 0
      },
      wx_key: '',
      expire_time: '',
      ischecked: false,
      ischecked1: false,
      loginMode: 'account',
      activeName: 'first', // tab切换
      formData: {
        account: '',
        password: '',
        vercode: '',
        mobile: '',
        dynacode: '',
        key: '',
        checked: false,
        autoLoginRange: 7
      }, // 表单数据
      captcha: {
        id: '',
        img: ''
      }, // 验证码
      dynacodeData: {
        seconds: 120,
        timer: null,
        codeText: vm.$lang('tmdDOTco.00339', vm.$route),
        isSend: false
      }, // 动态码
      isSub: false, // 提交防重复
      registerConfig: {
        is_enable: 1
      },
      accountRules: {
        account: [
          {
            required: true,
            message: vm.$lang('tmdDOTco.00340', vm.$route),
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: vm.$lang('tmdDOTco.00341', vm.$route),
            trigger: 'blur'
          }
        ],
        vercode: [
          {
            required: true,
            message: vm.$lang('tmdDOTco.00342', vm.$route),
            trigger: 'blur'
          }
        ]
      },
      mobileRules: {
        mobile: [
          {
            required: true,
            validator: isMobile,
            trigger: 'blur'
          }
        ],
        vercode: [
          {
            required: true,
            message: vm.$lang('tmdDOTco.00343', vm.$route),
            trigger: 'blur'
          }
        ],
        dynacode: [
          {
            required: true,
            message: vm.$lang('tmdDOTco.00344', vm.$route),
            trigger: 'blur'
          }
        ]
      },
      wechatRules: {
        mobile: [
          {
            required: true,
            validator: isMobile,
            trigger: 'blur'
          }
        ],
        vercode: [
          {
            required: true,
            message: vm.$lang('tmdDOTco.00345', vm.$route),
            trigger: 'blur'
          }
        ],
        dynacode: [
          {
            required: true,
            message: vm.$lang('tmdDOTco.00346', vm.$route),
            trigger: 'blur'
          }
        ]
      },
      codeRules: {
        mobile: [
          {
            required: true,
            validator: isMobile,
            trigger: 'blur'
          }
        ],
        vercode: [
          {
            required: true,
            message: vm.$lang('tmdDOTco.00347', vm.$route),
            trigger: 'blur'
          }
        ]
      },
      loadingAd: true,
      adList: [],
      backgroundColor: '',
      img: '',
      third_party: 0
    }
  },
  created() {
    this.ischecked = this.$route.params.third_party
    if (this.ischecked) {
      this.weixinLogin()
    }
    this.getAdList()
    this.getCaptcha()
    this.getRegisterConfig()

    let that = this
    document.onkeypress = function (e) {
      var keycode = document.all ? event.keyCode : e.which
      if (keycode == 13) {
        if (that.activeName == 'first') {
          that.accountLogin('ruleForm') // 登录方法名
        } else {
          that.mobileLogin('mobileRuleForm') // 登录方法名
        }

        return false
      }
    }
  },
  watch: {
    'dynacodeData.seconds': {
      handler(newValue, oldValue) {
        if (newValue == 0) {
          clearInterval(this.dynacodeData.timer)
          this.dynacodeData = {
            seconds: 120,
            timer: null,
            codeText: this.$lang('tmdDOTco.00348', this.$route),
            isSend: false
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getAdList() {
      adList({
        keyword: 'NS_PC_LOGIN'
      })
        .then((res) => {
          if (res.code == 0 && res.data.adv_list) {
            this.adList = res.data.adv_list
            for (let i = 0; i < this.adList.length; i++) {
              if (this.adList[i].adv_url)
                this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
            }
            this.backgroundColor = this.adList[0].background
          }

          this.loadingAd = false
        })
        .catch((err) => {
          this.loadingAd = false
        })
    },
    handleClick(tab, event) {
      if (this.activeName == 'first') {
        this.loginMode = 'account'
      } else {
        this.loginMode = 'mobile'
      }
    },
    handleChange(curr, pre) {
      this.backgroundColor = this.adList[curr].background
    },
    /**
     * 账号登录
     */
    accountLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var data = {
            username: this.formData.account,
            password: this.formData.password
          }

          if (this.captcha.id != '') {
            data.captcha_id = this.captcha.id
            data.captcha_code = this.formData.vercode
          }

          if (this.formData.checked) {
            data.autoLoginRange = this.formData.autoLoginRange
          }

          if (this.isSub) return
          this.isSub = true

          this.$store
            .dispatch('member/login', data)
            .then((res) => {
              if (res.code >= 0) {
                this.$message({
                  message: this.$lang('tmdDOTco.00349', this.$route),
                  type: 'success'
                })
                if (this.$route.query.redirect) {
                  const a = this.$route.query.redirect
                  const b = this.$route.query
                  this.$router.push(this.$route.query.redirect)
                } else {
                  this.$router.push({
                    name: 'member'
                  })
                }
              } else {
                this.isSub = false
                this.getCaptcha()
                this.$message({
                  message: res.message,
                  type: 'warning'
                })
              }
            })
            .catch((err) => {
              this.isSub = false
              this.$message.error(err.message)
              this.getCaptcha()
            })
        } else {
          return false
        }
      })
    },

    /**
     * 手机号登录
     */
    mobileLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var data = {
            mobile: this.formData.mobile,
            key: this.formData.key,
            code: this.formData.dynacode
          }

          if (this.captcha.id != '') {
            data.captcha_id = this.captcha.id
            data.captcha_code = this.formData.vercode
          }

          if (this.isSub) return
          this.isSub = true

          this.$store
            .dispatch('member/mobile_login', data)
            .then((res) => {
              if (res.code >= 0) {
                this.$message({
                  message: this.$lang('tmdDOTco.00350', this.$route),
                  type: 'success'
                })
                if (this.$route.query.redirect) {
                  this.$router.push(this.$route.query.redirect)
                } else {
                  this.$router.push({
                    name: 'member'
                  })
                }
              } else {
                this.isSub = false
                this.getCaptcha()
                this.$message({
                  message: res.message,
                  type: 'warning'
                })
              }
            })
            .catch((err) => {
              this.isSub = false
              this.$message.error(err.message)
              this.getCaptcha()
            })
        } else {
          return false
        }
      })
    },

    /**
     * 微信登录
     */
    wechatLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var data = {
            mobile: this.formData.mobile,
            key: this.formData.key,
            code: this.formData.dynacode
          }

          if (this.captcha.id != '') {
            data.captcha_id = this.captcha.id
            data.captcha_code = this.formData.vercode
          }

          if (this.isSub) return
          this.isSub = true

          this.$store
            .dispatch('wechat/wechatLogin', data)
            .then((res) => {
              if (res.code >= 0) {
                this.$message({
                  message: this.$lang('tmdDOTco.00351', this.$route),
                  type: 'success'
                })
                if (this.$route.query.redirect) {
                  this.$router.push(this.$route.query.redirect)
                } else {
                  this.$router.push({
                    name: 'member'
                  })
                }
              } else {
                this.isSub = false
                this.getCaptcha()
                this.$message({
                  message: res.message,
                  type: 'warning'
                })
              }
            })
            .catch((err) => {
              this.isSub = false
              this.$message.error(err.message)
              this.getCaptcha()
            })
        } else {
          return false
        }
      })
    },
    weixinLogin() {
      this.ischecked = true
      this.$store.dispatch('wechat/logincode').then((res) => {
        if (res.code >= 0) {
          this.img = res.data.qrcode
          this.wx_key = res.data.key
          this.expire_time = res.data.expire_time
          this.qrcodeData.timer = setInterval(() => {
            this.checkLogin()
          }, 2000)
        }
      })
    },

    // 检测是否扫码
    checkLogin() {
      this.qrcodeData.time += 2
      if (this.qrcodeData.time > this.expire_time) {
        clearInterval(this.qrcodeData.timer)
        return
      }
      var data = {
        key: this.wx_key
      }
      this.$store
        .dispatch('wechat/checklogin', data)
        .then((res) => {
          if (res.code >= 0) {
            if (res.data.token != undefined) {
              this.$message({
                message: this.$lang('tmdDOTco.00352', this.$route),
                type: 'success'
              })
              if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect)
              } else {
                this.$router.push({
                  name: 'member'
                })
              }
            } else {
              this.ischecked1 = true
            }

            clearInterval(this.qrcodeData.timer)
          }
        })
        .catch((err) => {
          console.log(err.message)
        })
    },

    closeWx() {
      this.ischecked = false
    },
    closeWx1() {
      this.ischecked = false
      this.ischecked1 = false
    },

    /**
     * 获取注册配置
     */
    getRegisterConfig() {
      registerConfig()
        .then((res) => {
          if (res.code >= 0) {
            this.registerConfig = res.data.value
            if (this.registerConfig.register.indexOf('username') != -1) {
              this.loginMode = 'account'
            } else {
              this.loginMode = 'mobile'
            }
          }
        })
        .catch((err) => {
          console.log(err.message)
        })
    },
    /**
     * 获取验证码
     */
    getCaptcha() {
      captcha({
        captcha_id: this.captcha.id
      })
        .then((res) => {
          if (res.code >= 0) {
            this.captcha.id = res.data.id
            this.captcha.img = res.data.img
            this.captcha.img = this.captcha.img.replace(/\r\n/g, '')
          }
        })
        .catch((err) => {
          this.$message.error(err.message)
        })
    },
    /**
     * 发送手机动态码
     */
    sendMobileCode(formName) {
      if (this.dynacodeData.seconds != 120) return
      this.$refs[formName].clearValidate('dynacode')

      this.$refs[formName].validateField('mobile', (valid) => {
        if (valid) {
          return false
        }
      })
      this.$refs[formName].validateField('vercode', (valid) => {
        if (!valid) {
          mobileCode({
            mobile: this.formData.mobile,
            captcha_id: this.captcha.id,
            captcha_code: this.formData.vercode
          })
            .then((res) => {
              if (res.code >= 0) {
                this.formData.key = res.data.key
                if (
                  this.dynacodeData.seconds == 120 &&
                  this.dynacodeData.timer == null
                ) {
                  this.dynacodeData.timer = setInterval(() => {
                    this.dynacodeData.seconds--
                    this.dynacodeData.codeText =
                      this.$lang('tmdDOTco.00353L', this.$route) +
                      this.dynacodeData.seconds +
                      this.$lang('tmdDOTco.00353R', this.$route)
                  }, 1000)
                }
              }
            })
            .catch((err) => {
              this.$message.error(err.message)
            })
        } else {
          return false
        }
      })
    },
    /**
     * 发送微信绑定手机动态码
     */
    sendWechatMobileCode(formName) {
      if (this.dynacodeData.seconds != 120) return
      this.$refs[formName].clearValidate('dynacode')

      this.$refs[formName].validateField('mobile', (valid) => {
        if (valid) {
          return false
        }
      })
      this.$refs[formName].validateField('vercode', (valid) => {
        if (!valid) {
          wechatMobileCode({
            mobile: this.formData.mobile,
            captcha_id: this.captcha.id,
            captcha_code: this.formData.vercode
          })
            .then((res) => {
              if (res.code >= 0) {
                console.log(res.data.code)
                this.formData.key = res.data.key
                if (
                  this.dynacodeData.seconds == 120 &&
                  this.dynacodeData.timer == null
                ) {
                  this.dynacodeData.timer = setInterval(() => {
                    this.dynacodeData.seconds--
                    this.dynacodeData.codeText =
                      this.$lang('tmdDOTco.00354L', this.$route) +
                      this.dynacodeData.seconds +
                      this.$lang('tmdDOTco.00354R', this.$route)
                  }, 1000)
                }
              }
            })
            .catch((err) => {
              this.$message.error(err.message)
            })
        } else {
          return false
        }
      })
    }
  }
}
